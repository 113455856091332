export const API_ADMIN_BASEURL = process.env.VUE_APP_API_URL;
export const API_ADMIN_TIMEOUT = process.env.VUE_APP_API_TIMEOUT;

export const API_INTERCOM_BASEURL = `${process.env.VUE_APP_API_URL}/v1/support/intercom`;
export const API_INTERCOM_TIMEOUT = process.env.VUE_APP_API_TIMEOUT;

export const API_MEDIA_BASEURL = process.env.VUE_APP_API_URL.slice(0, -4);
export const API_MEDIA_TIMEOUT = process.env.VUE_APP_API_TIMEOUT;

function createSwitchMap(baseURL) {
  return [
    {
      name: 'eu',
      label: 'UK',
      icon: 'globe-uk-eu',
      baseURL,
      country: ['default', 'gb', 'de', 'ch', 'us'],
    },
    {
      name: 'uae',
      label: 'UAE',
      icon: 'globe-uae',
      baseURL: baseURL.replace(/^(https?:\/\/)uk([.-])/, '$1uae$2'),
      country: ['ae'],
    },
  ];
}

export const API_SWITCH_VAR = '_easytip-server';
export const API_SWITCH_ENABLED = process.env.VUE_APP_API_SWITCH === 'true';
export const API_SWITCH_ADMIN = createSwitchMap(API_ADMIN_BASEURL);
export const API_SWITCH_INTERCOM = createSwitchMap(API_INTERCOM_BASEURL);
export const API_SWITCH_MEDIA = createSwitchMap(API_MEDIA_BASEURL);
